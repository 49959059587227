<template>
  <section class="px-md-2" style="min-height: 80vh;">
    <div class="mt-md-3 mb-2">
      <div class="w-50">
        <div class="exxtra-input">
          <span class="leading">
            <Search />
          </span>
          <input v-model="searchFilter" type="search" placeholder="Search Blogs">
        </div>
      </div>
    </div>
    <b-row v-if="blogs.length > 0">
      <b-col v-for="(blog, i) in blogs" :key="i" md="4">
        <b-card no-body class="overflow-hidden rounded-lg">
          <b-row no-gutters>
            <b-col md="5">
              <b-card-img fluid 
              :src="getActualValueFromSource(blog, 'featured_image.path', require('@/assets/images/pages/blog-default.webp'))"
              
              alt="Image" class="rounded-0"
              />
            </b-col>
            <b-col md="7">
              <div class="card-body">
                <div>
                  <h5 class="blog-title-">
                    {{ getValueFromSource(blog, 'title') }}
                  </h5>
                </div>
                <div>
                  <p class="blog-content-" style="line-height: 14px">
                    {{ extractTextContentFromHTML(getValueFromSource(blog, 'content')) }}
                  </p>
                  <div>
                    <router-link :to="{ name: 'single-blog', params: { slug: getValueFromSource(blog, 'slug')} }">
                      <h6 class="text-secondary">
                        Read More ->
                      </h6>
                    </router-link>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <div v-else-if="!loading">
      <h3 class="my-5">
        No content available.
      </h3>
    </div>

    <div class="d-flex justify-content-end align-items-center">
      <!-- pagination -->
      <!--<div class="my-2">-->
      <!--  <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item">-->
      <!--    <template #prev-text>-->
      <!--      <feather-icon icon="ChevronLeftIcon" size="18" />-->
      <!--    </template>-->
      <!--    <template #next-text>-->
      <!--      <feather-icon icon="ChevronRightIcon" size="18" />-->
      <!--    </template>-->
      <!--  </b-pagination>-->
      <!--</div>-->
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BBadge,
  BCardImg,
  BOverlay,
  BPagination
} from "bootstrap-vue";
import { get } from "lodash";
import Search from '@/assets/svg/Search.svg';
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const watchHandler = {
  handler() {
    this.fetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.fetchAllData()
  },
  immediate: false
}


export default {
  name: "ListBlogs",
  components: {
    BOverlay,
    BRow,
    BCard,
    BCol,
    BBadge,
    BCardImg,
    Search,
    BPagination
  },
  data() {
    return {
      loading: true,
      blogs: [],
      searchFilter: "",

      debounceFetchAllData: null
    }
  },
  computed: {
    settings(){
      return this.$store.getters[`auth/settings`];
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },

  watch: {
    searchFilter: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
  },
  created() {
    this.fetchAllData();

    const {
      search = "",
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.perPage = +limit;
    this.searchFilter = search;
    this.currentPage = +page;

    this.$socket.client.on("REFRESH_PAGE_DATA", async (payload) => {
      if (payload.name && payload.name === this.$route.name) {
        await this.fetchAllData()
      }
    });
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter,
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().sharedService.fetchBlogPosts(query);
        const { data, pagination } = request.data;
        this.blogs = data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

.blog-title- {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-content- {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
